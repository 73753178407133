import { useState, useEffect, useMemo, forwardRef } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isValid } from "date-fns";
import styled from "styled-components";

import { useUpdateTaskPart } from "../task/mutations/useUpdateTaskPart";
import { TaskPartStatus } from "../../api/core/taskAPI";
import { useKitboxes } from "../admin/kitbox/hooks/useKitboxes";
import QueryWrapper from "../../components/application/QueryWrapper";

import MainArea from "../../components/layout/MainArea";
import { H1, TB } from "../../components/texts";
import { Input, InputWithLabel, SelectForm, InputCheckboxClean } from "../../components/inputs";
import { Button, CleanButton, SecondaryButton } from "../../components/buttons";
import StandardTableStyle from "../../components/application/StandardTableStyle";
import StandardTableContent from "../../components/application/StandardTableContent";
import TablePaginationNav from "../../components/application/TablePaginationNav";
import TaskEdit from "../task/TaskEdit";
import { standardDateOnly } from "../../common/date";
import { Horizontal } from "../../components/layout/FlexGrid";
import Spacer from "../../components/helpers/Spacer";
import { CalendarIcon, FlagIcon } from "../../components/icons";
import { usePatchTaskKittingBox } from "../task/mutations/usePatchTaskKittingBox";
import { useRequisitions } from "../task/hooks/useRequisitions";
import { fuzzyFilter } from "../../components/application/FuzzyFilter";
import { useSearchParams } from "../application/hooks/useSearchParams";

const PartRequests = () => {
    const [isTaskEditorOpen, setIsTaskEditorOpen] = useState(false);
    const [editTaskId, setEditTaskId] = useState(null);
    const [taskEditorInitialData, setTaskEditorInitialData] = useState(null);

    const { t } = useTranslation();
    const methods = useForm();

    const tasks = useRequisitions();
    const kitboxes = useKitboxes();
    const { mutate: updateTaskPart } = useUpdateTaskPart();
    const { mutate: updateTaskKittingBox } = usePatchTaskKittingBox();

    const { getParam, setParam } = useSearchParams();
    const page = (() => {
        const parsedPage = Number.parseInt(getParam("page"));
        return Number.isNaN(parsedPage) ? 0 : parsedPage - 1;
    })();

    const search = methods.watch("search");
    const checkboxes = methods.watch("checkboxes");
    const statusFilterValue = methods.watch("status")?.value || "all_open_orders";
    const selectedRequests = checkboxes
        ?.filter((item) => item.checked)
        ?.map((item) => item.requestId);

    const initialState = useMemo(
        () => ({
            pagination: {
                pageSize: 30,
            },
        }),
        []
    );

    const data = useMemo(() => {
        if (!tasks?.data || !tasks.data.length) return [];

        let allRequestedParts = [];

        tasks.data.forEach((task) => {
            if (task.parts?.length) {
                task.parts.forEach((part) => {
                    const count = part?.count || 1;
                    allRequestedParts.push({
                        ...task,
                        requestedPart: {
                            ...part,
                            count,
                            cost: part?.part?.price ? part.part.price * count : null,
                        },
                    });
                });
            }
        });

        const allRequestsNotInStock = allRequestedParts.filter(
            (item) => !item.requestedPart.part.stock
        );
        const requestsWithSelectedStatus = allRequestsNotInStock?.filter((item) =>
            statusFilterValue === "all_open_orders"
                ? item.status !== "invoiced"
                : statusFilterValue === "invoiced"
                  ? item.status === "invoiced" && item.requestedPart?.status === "paid"
                  : (item.status !== "invoiced" || item.requestedPart?.status !== "paid") &&
                    (item.requestedPart?.status || "request") === statusFilterValue
        );

        return requestsWithSelectedStatus;
    }, [tasks?.data, statusFilterValue]);

    const kitboxValues = useMemo(
        () =>
            kitboxes?.data?.length
                ? kitboxes.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                      customer_id: item.customer_id,
                  }))
                : [],
        [kitboxes?.data]
    );

    // The options are a different array just to keep track of which kitboxes are taken.
    // If a kitbox is taken, the label will be changed to "Kitbox name (taken)"
    const kitboxOptions = useMemo(() => {
        if (!kitboxValues) return [];
        return kitboxValues?.map((option) =>
            data?.some((item) => item.kitting_box_id === option.value)
                ? { ...option, label: `${option.label} (${t("taken")})` }
                : option
        );
    }, [kitboxValues, data]);

    // Add request ids to row checkbox values
    useEffect(() => {
        const allRequestIds =
            data?.map((part) => ({
                requestId: part.requestedPart.id,
                checked: false,
            })) || [];
        methods.setValue("checkboxes", allRequestIds);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                header: "",
                id: "checkbox",
                accessorKey: "requestedPart.id",
                cell: ({ row, getValue }) => (
                    <CheckboxInputContainer>
                        <InputCheckboxClean
                            id={`check-${getValue()}`}
                            {...methods.register(`checkboxes.${row.id}.checked`)}
                        />
                    </CheckboxInputContainer>
                ),
                enableSorting: false,
            },
            {
                header: t("status"),
                accessorFn: (row) =>
                    row.status === "invoiced" && row.requestedPart.status === "paid"
                        ? "invoiced"
                        : row.requestedPart.status,
                cell: ({ getValue }) => t(`status-${getValue() || TaskPartStatus.REQUEST}`),
            },
            {
                header: t("date"),
                accessorKey: "start",
                cell: ({ getValue }) => {
                    const value = getValue();
                    return value && isValid(new Date(value)) ? standardDateOnly(value) : "";
                },
            },
            {
                header: t("priority"),
                accessorKey: "priority",
                cell: ({ getValue }) => (getValue() === "high" ? <FlagIcon /> : null),
            },
            {
                header: t("order"),
                accessorKey: "workorder_id",
                cell: ({ getValue, row }) => (
                    <CleanButton type="button" onClick={() => openEditorForTask(row.original.id)}>
                        <TB $link>{getValue()}</TB>
                    </CleanButton>
                ),
            },
            {
                header: t("vehicle"),
                accessorKey: "unit.int_id",
            },
            {
                header: t("part_name"),
                accessorKey: "requestedPart.part.name",
                cell: ({ row, getValue }) => {
                    const partId = row.original.requestedPart.part_id;
                    return (
                        <Link to={`/materials/parts/${partId}/edit`}>
                            <TB $link>{getValue()}</TB>
                        </Link>
                    );
                },
            },
            {
                header: t("description_2"),
                accessorKey: "requestedPart.part.description",
                id: "description",
                enableSorting: false,
            },
            {
                header: t("quantity"),
                accessorKey: "requestedPart.count",
                enableSorting: false,
            },
            {
                header: t("cost"),
                id: "cost",
                accessorKey: "requestedPart.cost",
                cell: ({ getValue }) => {
                    return getValue() || "–";
                },
                enableSorting: false,
            },
            {
                header: t("supplier"),
                id: "supplier",
                accessorKey: "requestedPart.part.supplier_id",
                cell: ({ getValue }) => {
                    return getValue() || "–";
                },
                enableSorting: false,
            },
            {
                header: t("invoice_or_delivery_number"),
                accessorKey: "requestedPart.invoice_number",
                enableSorting: false,
                cell: ({ getValue, row }) => (
                    <InputStyled
                        key={`invoice_number-${row.original.requestedPart.id}`}
                        defaultValue={getValue()}
                        onBlur={(e) =>
                            onInvoiceUpdate(e.target.value, row.original.requestedPart.id)
                        }
                    />
                ),
            },
            {
                header: t("eta"),
                accessorKey: "requestedPart.eta",
                enableSorting: false,
                cell: ({ getValue, row }) => {
                    const value = getValue();
                    return (
                        <ReactDatePicker
                            dateFormat="dd.MM.yyyy"
                            selected={value && isValid(new Date(value)) ? new Date(value) : null}
                            onChange={(date) => onEtaUpdate(date, row.original.requestedPart.id)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<DateInputInteral />}
                            portalId="root-portal"
                            disabled={row.original.requestedPart?.status === "arrived"}
                        />
                    );
                },
            },
            {
                header: t("kitbox"),
                accessorKey: "kitting_box_id",
                cell: ({ getValue, row }) => {
                    const value = getValue();
                    return (
                        <SelectStyled
                            //Key makes sure the field is reset when rows change
                            key={`kitting_box_id-${row.original.requestedPart.id}`}
                            //
                            name={`kitting_box_id.${row.index}`}
                            defaultValue={kitboxValues?.find((item) => item.value === value)}
                            options={kitboxOptions?.filter(
                                (option) => option.customer_id === row.original.host_id
                            )}
                            onChange={(option) =>
                                onKittingBoxUpdate(option?.value, row.original.id)
                            }
                            isClearable
                        />
                    );
                },
            },
        ],
        [kitboxOptions]
    );

    const DateInputInteral = forwardRef(({ onClick, ...props }, ref) => {
        return (
            <DateInputContainer onClick={onClick}>
                <CalendarIconStyled>
                    <CalendarIcon />
                </CalendarIconStyled>

                <Input name="Starttidspunkt" {...props} />
            </DateInputContainer>
        );
    });

    const table = useReactTable({
        columns,
        data,
        initialState,
        state: {
            pagination: {
                pageIndex: page,
                pageSize: 30,
            },
        },
        autoResetAll: false,
        autoResetGlobalFilter: false,
        autoResetSortBy: false,
        autoResetPageIndex: false,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    useEffect(() => {
        table.setGlobalFilter(search);
    }, [search, table]);

    function openEditorForTask(id) {
        setEditTaskId(id);
        setTaskEditorInitialData(null);
        setIsTaskEditorOpen(true);
    }

    function closeTaskEditor(data = null) {
        setEditTaskId(null);
        setTaskEditorInitialData(data);
        setIsTaskEditorOpen(false);
    }

    function createNewTask() {
        setEditTaskId(null);
        setIsTaskEditorOpen(true);
    }

    function resetTaskEditor(data = null) {
        closeTaskEditor(data);
        setTimeout(() => setIsTaskEditorOpen(true), 50);
    }

    function onInvoiceUpdate(value, requestId) {
        updateTaskPart({ id: requestId, invoice_number: value || null });
    }

    function onKittingBoxUpdate(newValue, taskId) {
        updateTaskKittingBox({ id: taskId, kitting_box_id: newValue || null });
    }

    function onEtaUpdate(newDate, requestId) {
        updateTaskPart({ id: requestId, eta: newDate });
    }

    function onStatusUpdate(newStatus) {
        const dataToPatch = selectedRequests?.map((id) => ({ id, status: newStatus }));

        if (dataToPatch?.length) {
            Promise.all(dataToPatch.map((part) => updateTaskPart(part)));
        }
    }

    return (
        <MainArea>
            <Header>
                <H1>{t("requisitions")}</H1>
                <Spacer />
                <Button onClick={createNewTask} $bold>
                    {t("new_request")}
                </Button>
            </Header>

            <FormProvider {...methods}>
                <form>
                    <InputWithLabel
                        name="search"
                        label={t("search_requisitions")}
                        style={{ maxWidth: "27rem" }}
                    />

                    <OptionsRow>
                        <SelectStyled
                            name={`status`}
                            defaultValue={{ value: "all_open_orders", label: t("all_open_orders") }}
                            label={t("show_status")}
                            options={[
                                { value: "all_open_orders", label: t("all_open_orders") },
                                { value: TaskPartStatus.REQUEST, label: t("status-request") },
                                { value: TaskPartStatus.ORDERED, label: t("status-ordered") },
                                { value: TaskPartStatus.ARRIVED, label: t("status-arrived") },
                                { value: TaskPartStatus.PAID, label: t("status-paid") },
                                { value: "invoiced", label: t("status-invoiced") },
                            ]}
                        />

                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.REQUEST)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_requested")}
                        </StatusActionButton>
                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.ORDERED)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_ordered")}
                        </StatusActionButton>
                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.ARRIVED)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_arrived")}
                        </StatusActionButton>
                        <StatusActionButton
                            onClick={() => onStatusUpdate(TaskPartStatus.PAID)}
                            disabled={!selectedRequests?.length}
                        >
                            {t("mark_as_paid")}
                        </StatusActionButton>
                    </OptionsRow>

                    <QueryWrapper data={[tasks]}>
                        {rowModel.rows.length > 0 ? (
                            <TableContainer>
                                <TableStyled>
                                    <StandardTableContent
                                        headerGroups={headerGroups}
                                        rowModel={rowModel}
                                    />
                                </TableStyled>
                            </TableContainer>
                        ) : (
                            <NothingToShow>
                                <TB>{t("no_requisitions_to_show")}</TB>
                            </NothingToShow>
                        )}
                        <TablePaginationNav
                            pageCount={table.getPageCount()}
                            previousPage={() => {
                                if (table.getCanPreviousPage()) {
                                    setParam("page", page);
                                }
                            }}
                            canPreviousPage={table.getCanPreviousPage()}
                            nextPage={() => {
                                if (table.getCanNextPage()) {
                                    setParam("page", page + 2);
                                }
                            }}
                            canNextPage={table.getCanNextPage()}
                            pageOptions={table.getPageOptions()}
                            gotoPage={(pageIndex) => {
                                if (table.getPageCount() > pageIndex) {
                                    setParam("page", pageIndex + 1);
                                }
                            }}
                            pageIndex={table.getState().pagination.pageIndex}
                        />
                    </QueryWrapper>
                </form>
            </FormProvider>

            {isTaskEditorOpen && (
                <TaskEdit
                    editTaskId={editTaskId}
                    isOpen={isTaskEditorOpen}
                    onClose={closeTaskEditor}
                    initialData={taskEditorInitialData}
                    resetTaskEditor={resetTaskEditor}
                />
            )}
        </MainArea>
    );
};

export default PartRequests;

const Header = styled(Horizontal)`
    align-items: center;
    flex-wrap: wrap;
`;

const DateInputContainer = styled.div``;

const CheckboxInputContainer = styled.section`
    position: relative;
`;

const CalendarIconStyled = styled.div`
    position: absolute;
    left: calc(100%);
    transform: translate(-125%, 20%);
    cursor: pointer;
`;

const StatusActionButton = styled(SecondaryButton).attrs({ type: "button" })`
    padding-top: 0.7rem;
    margin: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
`;

const SelectStyled = styled(SelectForm)`
    min-width: 10rem;
    margin: 0.3rem 0;
`;

const OptionsRow = styled.section`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    ${SelectStyled} {
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
`;

const TableContainer = styled.article`
    margin-top: 1rem;
    overflow: auto;
`;

const TableStyled = styled(StandardTableStyle)`
    min-width: 130rem;

    td:first-child,
    th:first-child {
        min-width: 1rem;
        max-width: 2rem;
    }

    tr {
        td:nth-child(4) {
            text-align: center;
            padding-right: 2rem;
        }

        th:nth-child(9),
        th:nth-child(10) {
            text-align: right;
        }
        td:nth-child(9),
        td:nth-child(10) {
            text-align: right;
            padding-right: 2rem;
        }

        td:nth-child(12),
        td:nth-child(13),
        td:nth-child(14),
        td:nth-child(15) {
            max-width: 10rem;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const InputStyled = styled(Input)`
    margin: 0.3rem 0;
    height: 2.5rem;
`;
