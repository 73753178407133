import { useEffect, useMemo, useRef, useState } from "react";
import { startOfDay, startOfYear, format, differenceInSeconds, isWithinInterval } from "date-fns";
import { useTranslation } from "react-i18next";
import { nb } from "date-fns/locale";
import * as d3 from "d3";
import styled from "styled-components";

import { useResizeObserver } from "../../../components/helpers/hooks/useResizeObserver";
import { useMapHighlight } from "./hooks/useMapHighlight";

import UnitStatusRowInfo from "./UnitStatusRowInfo";
import { TB } from "../../../components/texts";

const margin = { top: 20, right: 0, bottom: 110, left: 304 };

const rowHeight = 80;
const barHeight = 68;

const UnitStatusTable = ({
    engineStatus,
    allUnits,
    selectedUnits,
    fromDate,
    toDate,
    sensorColors,
    handleRemoveUnitFromSelected,
    unitDefectHistories,
    unitTasks,
}) => {
    const [ignitionStatusOnData, setIgnitionStatusOnData] = useState({});

    const graphContainerRef = useRef(null);
    const svgRef = useRef(null);
    const axisBottomRef = useRef(null);
    const tooltipAreaRef = useRef(null);
    const tooltipGroupRef = useRef(null);

    const {
        highlightedVehicleId,
        setHighlightedVehicleId,
        setHighlightedTime,
        setClosestDataPointIndexForUnit,
    } = useMapHighlight();

    const { t } = useTranslation();
    const isSensorDataAllLoaded =
        engineStatus != null && engineStatus.every((item) => item?.isSuccess);

    const size = useResizeObserver(graphContainerRef);

    const selectedUnitsWithData = useMemo(
        () =>
            selectedUnits?.length
                ? selectedUnits?.filter(
                      (unit) =>
                          engineStatus?.find((item) => item.data?.unitId === unit.value)?.data
                              ?.statuses?.length > 0
                  )
                : [],
        [selectedUnits, engineStatus]
    );

    useEffect(() => {
        if (!axisBottomRef.current || !size) return;

        drawGraphForData();
    }, [
        axisBottomRef,
        graphContainerRef.current,
        size,
        fromDate,
        toDate,
        selectedUnitsWithData,
        isSensorDataAllLoaded,
    ]);

    function drawGraphForData() {
        const { width, height } = size;

        const boundedWidth = width - margin.left;
        const boundedHeight = height - margin.bottom - 10;

        const xScale = d3
            .scaleTime()
            .domain([fromDate, toDate])
            .range([0 + margin.left, width - margin.right]);

        const xDurationInSecondsScale = d3
            .scaleLinear()
            .domain([0, differenceInSeconds(new Date(toDate), new Date(fromDate))])
            .range([0, boundedWidth]);

        const axisBottomGenerator = d3
            .axisBottom(xScale)
            .ticks(width / 150)
            .tickPadding(8)
            .tickSize(10)
            .tickFormat((value) => (value instanceof Date ? formatTickDate(value) : String(value)));

        if (axisBottomRef.current) {
            d3.select(axisBottomRef.current)
                .call(axisBottomGenerator)
                .attr(
                    "transform",
                    `translate(0, ${(selectedUnitsWithData?.length || 0) * rowHeight})`
                );
        }

        const ignitionStatusBlocksForSelectedUnits = selectedUnitsWithData?.map((unit) => {
            const engineDataForUnit = engineStatus?.find((item) => item.data?.unitId === unit.value)
                ?.data?.statuses;
            return {
                unitId: unit.value,
                blocks: getIgnitionStatusBlocks(engineDataForUnit),
            };
        });

        const defectStatusBlocksForSelectedUnits = selectedUnitsWithData?.map((unit) => {
            const defectDataForUnit = unitDefectHistories?.data?.filter(
                (item) => item.unit_id === unit.value
            );
            defectDataForUnit?.sort((a, b) => new Date(a.defected_at) - new Date(b.defected_at));
            return (defectDataForUnit ?? []).map((defect) => {
                const dateFrom = defect.defected_at;
                const dateTo = defect.fixed_at ? defect.fixed_at : new Date();
                return {
                    ...defect,
                    dateFrom,
                    dateTo,
                };
            });
        });

        const unitMappedTasks = new Map();
        if (unitTasks.data) {
            for (const task of unitTasks.data) {
                if (!task.start || !task.end) continue;
                const existingTasks = unitMappedTasks.get(task.unit_id);
                if (existingTasks) {
                    existingTasks.push(task);
                } else {
                    unitMappedTasks.set(task.unit_id, [task]);
                }
            }
        }

        const tasksBlocksForSelectedUnits = selectedUnitsWithData?.map((unit) => {
            return (unitMappedTasks.get(unit.value) ?? []).map((task) => ({
                ...task,
                dateFrom: task.start,
                dateTo: task.end,
            }));
        });

        setIgnitionStatusOnData(
            getEngineOnDurationBasedOnStatusBlocks(ignitionStatusBlocksForSelectedUnits)
        );

        tasksBlocksForSelectedUnits?.forEach((blocks, index) => drawUnitTasksGraph(blocks, index));

        defectStatusBlocksForSelectedUnits?.forEach((blocks, index) =>
            drawDefectStatusGraph(blocks, index)
        );

        ignitionStatusBlocksForSelectedUnits?.forEach(({ blocks }, index) =>
            drawIngitionStatusGraph(blocks, index)
        );

        if (tooltipAreaRef.current && tooltipGroupRef.current) {
            const tooltipArea = d3.select(tooltipAreaRef.current);
            const tooltipGroup = d3.select(tooltipGroupRef.current);

            tooltipArea
                .attr("width", boundedWidth)
                .attr("height", boundedHeight)
                .on("mousemove", handleTooltipAreaHover)
                .on("mouseleave", handleTooltipAreaMouseLeave);

            function handleTooltipAreaHover(e) {
                const mousePosition = d3.pointer(e);
                const hoveredDate = xScale.invert(mousePosition[0] + margin.left);
                const hoveredRowNumber = Math.ceil(mousePosition[1] / (rowHeight + 2)) || 1;
                const hoveredVehicleId = selectedUnitsWithData?.find(
                    (_, index) => hoveredRowNumber === index + 1
                )?.value;

                const sensorDataForUnit = engineStatus?.find(
                    (item) => item.data?.unitId === hoveredVehicleId
                )?.data?.statuses;

                const closestDataPointIndexForUnit = d3.leastIndex(
                    sensorDataForUnit,
                    (a, b) => getDistanceFromHoveredDate(a) - getDistanceFromHoveredDate(b)
                );

                function getDistanceFromHoveredDate(d) {
                    return Math.abs(
                        Number(new Date(d.timestamp).getTime()) -
                            Number(new Date(hoveredDate).getTime())
                    );
                }

                setHighlightedVehicleId(hoveredVehicleId);
                setHighlightedTime(hoveredDate);
                setClosestDataPointIndexForUnit(closestDataPointIndexForUnit);

                const ignitionStatusBlocksAtPosition = ignitionStatusBlocksForSelectedUnits.map(
                    ({ blocks }) =>
                        blocks.find(({ dateFrom, dateTo }) =>
                            isWithinInterval(hoveredDate, {
                                start: dateFrom,
                                end: dateTo,
                            })
                        )
                );

                const defectStatusBlocksAtPosition = defectStatusBlocksForSelectedUnits.map(
                    (blocks) =>
                        blocks.filter((block) => {
                            if (!block.dateFrom || !block.dateTo) return false;
                            return isWithinInterval(hoveredDate, {
                                start: new Date(block.dateFrom),
                                end: new Date(block.dateTo),
                            });
                        })
                );

                const taskBlocksAtPosition = tasksBlocksForSelectedUnits.map((blocks) =>
                    blocks.filter((block) => {
                        if (!block.dateFrom || !block.dateTo) return false;
                        return isWithinInterval(hoveredDate, {
                            start: new Date(block.dateFrom),
                            end: new Date(block.dateTo),
                        });
                    })
                );

                const countedUnitIds = new Map();
                const numberOfTaskVehiclesAtPosition = taskBlocksAtPosition.reduce((acc, curr) => {
                    for (const defect of curr) {
                        countedUnitIds.set(defect.unit_id, true);
                    }
                    return acc + curr.length;
                }, 0);

                const numberOfDefectVehiclesAtPosition = defectStatusBlocksAtPosition.reduce(
                    (acc, curr) => {
                        let count = 0;
                        for (const task of curr) {
                            if (!countedUnitIds.get(task.unit_id)) {
                                countedUnitIds.set(task.unit_id, true);
                                count++;
                            }
                        }

                        return acc + count;
                    },
                    0
                );

                const numberOfActiveVehiclesAtPosition = ignitionStatusBlocksAtPosition.reduce(
                    (count, block) => {
                        if (block == null) return count;

                        const { unitId, status } = block;
                        if (countedUnitIds.get(unitId)) return count;
                        return count + status === 1 ? 1 : 0;
                    },
                    0
                );

                tooltipGroup.style("display", "initial");
                tooltipGroup
                    .select("circle")
                    .attr(
                        "transform",
                        `translate(${mousePosition[0]}, ${
                            hoveredRowNumber * rowHeight - rowHeight / 2
                        })`
                    );
                tooltipGroup
                    .select("#hoverline")
                    .attr("d", `M${mousePosition[0]} 0 L${mousePosition[0]} ${boundedHeight} Z`);

                const tooltipWidth = 240;
                const tooltipDefaultXPosition = mousePosition[0] - tooltipWidth / 2;
                const tooltipRightPositionLimit = boundedWidth - tooltipWidth - 1;

                const tooltipContent = tooltipGroup
                    .select("#tooltipContent")
                    .attr(
                        "transform",
                        `translate(${
                            tooltipDefaultXPosition > tooltipRightPositionLimit
                                ? tooltipRightPositionLimit
                                : tooltipDefaultXPosition
                        }, ${boundedHeight + 10})`
                    );

                tooltipContent
                    .select("#tooltipDate")
                    .text(
                        capitalizeFirstLetter(
                            format(hoveredDate, "EEEE do MMMM, HH:mm", { locale: nb })
                        )
                    );

                tooltipContent
                    .select("#tooltipText")
                    .text(
                        `${numberOfActiveVehiclesAtPosition || 0} ${t("vehicles")} ${t(
                            "in_use"
                        )}`.toLowerCase()
                    );

                tooltipContent
                    .select("#tooltipDefects")
                    .text(
                        `${numberOfDefectVehiclesAtPosition || 0} ${t("vehicles")} ${t("with_defect")}`.toLowerCase()
                    );

                tooltipContent
                    .select("#tooltipTasks")
                    .text(
                        `${numberOfTaskVehiclesAtPosition || 0} ${t("vehicles")} ${t("in_service")}`.toLowerCase()
                    );
            }

            function handleTooltipAreaMouseLeave(e) {
                tooltipGroup.style("display", "none");
                setHighlightedVehicleId(null);
                setHighlightedTime(null);
                setClosestDataPointIndexForUnit(null);
            }
        }

        function drawIngitionStatusGraph(ignitionStatusBlocks, index) {
            const unitRow = d3.select(
                `#unitRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`
            );

            // Add rectangles for all on/off engine status periods
            unitRow
                .selectAll("rect")
                .data(ignitionStatusBlocks)
                .enter()
                .append("rect")
                .attr("y", () => rowHeight * index + (rowHeight - barHeight) / 2)
                .attr("x", (d) => xScale(d.dateFrom))
                .attr("height", barHeight)
                .attr("width", (d) => {
                    const widthBasedOnDuration = xDurationInSecondsScale(
                        differenceInSeconds(d.dateTo, d.dateFrom)
                    );

                    return widthBasedOnDuration > 0 ? widthBasedOnDuration : 0;
                })
                .attr("fill", (d) => (d.status === 1 ? "#B5E3BC" : "none"));
        }

        function drawDefectStatusGraph(defectStatusBlocks, index) {
            const unitRow = d3.select(
                `#unitDefectRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`
            );

            // Add rectangles for all defect status periods
            unitRow
                .selectAll("rect")
                .data(defectStatusBlocks)
                .enter()
                .append("rect")
                .attr("y", rowHeight * index + (rowHeight - barHeight) / 2)
                .attr("x", (d) => xScale(new Date(d.dateFrom)))
                .attr("height", barHeight)
                .attr("width", (d) => {
                    const widthBasedOnDuration = xDurationInSecondsScale(
                        differenceInSeconds(new Date(d.dateTo), new Date(d.dateFrom))
                    );

                    return widthBasedOnDuration > 0 ? widthBasedOnDuration : 0;
                })
                .attr("fill", "#F58D7B");
        }

        function drawUnitTasksGraph(tasksBlocks, index) {
            const unitRow = d3.select(
                `#unitTasksRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`
            );

            // Add rectangles for all task status periods
            unitRow
                .selectAll("rect")
                .data(tasksBlocks)
                .enter()
                .append("rect")
                .attr("y", rowHeight * index + (rowHeight - barHeight) / 2)
                .attr("x", (d) => xScale(new Date(d.dateFrom)))
                .attr("height", barHeight)
                .attr("width", (d) => {
                    const widthBasedOnDuration = xDurationInSecondsScale(
                        differenceInSeconds(new Date(d.dateTo), new Date(d.dateFrom))
                    );

                    return widthBasedOnDuration > 0 ? widthBasedOnDuration : 0;
                })
                .attr("fill", "#FFE780");
        }
    }

    // Group ignition status data into blocks with same status and add from/to dates
    function getIgnitionStatusBlocks(statuses) {
        let ignitionStatusBlocks = [];

        let prev = statuses[0];
        for (let i = 1; i < statuses.length; i++) {
            const dataPoint = statuses[i];
            if (prev.status === dataPoint.status && i !== statuses.length - 1) continue;

            ignitionStatusBlocks.push({
                status: prev.status,
                dateFrom: new Date(prev.minutes * 60000),
                dateTo: new Date(dataPoint.minutes * 60000),
            });
            prev = dataPoint;
        }

        return ignitionStatusBlocks;
    }

    function formatTickDate(date) {
        const isStartOfDay = date.getTime() === startOfDay(date).getTime();
        const isStartOfYear = date.getTime() === startOfYear(date).getTime();
        const formatOption = isStartOfYear ? "yyyy" : isStartOfDay ? "EEE dd.MM" : "HH:mm";
        return format(date, formatOption, { locale: nb });
    }

    function capitalizeFirstLetter(string) {
        return string && string[0].toUpperCase() + string.slice(1);
    }

    function getEngineOnDurationBasedOnStatusBlocks(statusBlocks) {
        return statusBlocks.reduce((allUnitTotals, { unitId, blocks }) => {
            let unitTotal = 0;
            for (const { status, dateFrom, dateTo } of blocks) {
                if (status === 1) {
                    unitTotal += differenceInSeconds(dateTo, dateFrom);
                }
            }

            allUnitTotals[unitId] = unitTotal;
            return allUnitTotals;
        }, {});
    }

    return (
        <MainContainer>
            {selectedUnitsWithData?.length > 0 && isSensorDataAllLoaded ? (
                <RowInfoContainer>
                    <TableTitle>
                        <TB>{t("usage_history")}</TB>
                    </TableTitle>
                    {selectedUnitsWithData?.map((selectedUnit, index) => (
                        <UnitStatusRowInfo
                            key={"unit-row-" + index}
                            color={sensorColors[index < 10 ? index : String(index)?.slice(-1)]}
                            dataForIgnitionOn={ignitionStatusOnData[selectedUnit.value]}
                            unitData={allUnits?.data?.find(
                                (unit) => unit.id === selectedUnit.value
                            )}
                            handleRemoveUnit={() =>
                                handleRemoveUnitFromSelected(selectedUnit.value)
                            }
                            isHovered={highlightedVehicleId === selectedUnit.value}
                            rowHeight={rowHeight}
                        />
                    ))}
                </RowInfoContainer>
            ) : null}

            <GraphContainer
                ref={graphContainerRef}
                $numberOfSelectedUnits={selectedUnitsWithData?.length || 0}
                pointerEvents="all"
            >
                {selectedUnitsWithData?.length > 0 && isSensorDataAllLoaded ? (
                    <svg ref={svgRef} width={size?.width} height={size?.height}>
                        {/* ROWS WITH DATA FOR EACH UNIT */}
                        {selectedUnitsWithData.map((unit, index) => (
                            <>
                                <g
                                    key={`unitRow-${
                                        unit.value
                                    }-${fromDate.getTime()}-${toDate.getTime()}-${index}-${
                                        size?.width
                                    }`}
                                    id={`unitRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`}
                                />
                                <g
                                    key={`unitDefectRow-${
                                        unit.value
                                    }-${fromDate.getTime()}-${toDate.getTime()}-${index}-${
                                        size?.width
                                    }`}
                                    id={`unitDefectRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`}
                                />
                                <g
                                    key={`unitTasksRow-${
                                        unit.value
                                    }-${fromDate.getTime()}-${toDate.getTime()}-${index}-${
                                        size?.width
                                    }`}
                                    id={`unitTasksRow-${fromDate.getTime()}-${toDate.getTime()}-${index}`}
                                />
                            </>
                        ))}

                        {/* BOTTOM AXIS */}
                        <AxisG ref={axisBottomRef} />

                        {/* GROUP WITHIN SVG BOUNDS */}
                        <g transform={`translate(${margin.left}, 0)`}>
                            {/* ALL TOOLTIP-RELATED ELEMENTS */}
                            <TooltipGroup ref={tooltipGroupRef}>
                                <HoverLinePath id={"hoverline"} />
                                <TooltipCircle r={6} />

                                <g id="tooltipContent">
                                    <TooltipBackgroundRect />

                                    <TooltipText x={10} y={24}>
                                        <tspan id="tooltipDate" x={240 / 2} textAnchor="middle" />
                                        <tspan
                                            id="tooltipText"
                                            dy={"1.5em"}
                                            x={240 / 2}
                                            textAnchor="middle"
                                        />
                                        <tspan
                                            id="tooltipTasks"
                                            dy={"1.5em"}
                                            x={240 / 2}
                                            textAnchor="middle"
                                        />
                                        <tspan
                                            id="tooltipDefects"
                                            dy={"1.5em"}
                                            x={240 / 2}
                                            textAnchor="middle"
                                        />
                                    </TooltipText>
                                </g>
                            </TooltipGroup>

                            {/* TOOLTIP HOVER AREA */}
                            <rect ref={tooltipAreaRef} fill="none" pointerEvents="all" />
                        </g>
                    </svg>
                ) : null}
            </GraphContainer>
        </MainContainer>
    );
};

export default UnitStatusTable;

const TooltipText = styled.text`
    font-size: 1rem;
    font-family: ${(p) => p.theme.font.t["font-family"]};
    fill: ${(p) => p.theme.color.neutral.base};
`;

const TooltipBackgroundRect = styled.rect`
    fill: #ffffff;
    stroke: ${(p) => p.theme.color.neutral.xlight};
    stroke-width: 1px;
    width: 240px;
    height: 110px;
`;

const TooltipGroup = styled.g`
    display: none;
    user-select: none;
`;

const HoverLinePath = styled.path`
    stroke: ${(p) => p.theme.color.primary.base};
    stroke-width: 2px;
`;

const TooltipCircle = styled.circle`
    stroke: ${(p) => p.theme.color.primary.base};
    stroke-width: 2px;
    fill: ${(p) => p.theme.color.ok.base};
`;

const MainContainer = styled.div`
    display: flex;
    margin-top: 2rem;
`;

const RowInfoContainer = styled.article`
    opacity: 0;
    animation: fadeIn 300ms ease forwards;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
    width: 100%;
    position: absolute;
    pointer-events: none;
`;

const TableTitle = styled.section`
    margin-bottom: 0.5rem;
`;

const GraphContainer = styled.section`
    height: calc(${(p) => p.$numberOfSelectedUnits * rowHeight}px + 120px);
    margin-top: 2rem;
    width: 100%;
`;

const AxisG = styled.g`
    .domain {
        stroke: ${(p) => p.theme.color.neutral.xlight};
        stroke-width: 2px;
        stroke: none;
    }

    .tick {
        line {
            stroke: ${(p) => p.theme.color.neutral.xlight};
            stroke-width: 2px;
        }

        text {
            font-family: ${(props) => props.theme.font.t["font-family"]};
            font-weight: ${(props) => props.theme.font.tb["font-weight"]};
            font-size: 0.8rem;
        }
    }
`;
